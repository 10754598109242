import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useFormContext, Controller } from 'react-hook-form'
import { Box, Heading, Text, Flex } from 'rebass/styled-components'
import { onModuleChange } from '../../helpers/Pagebuilder'
import Editor from '../general/Editor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const Wysiwyg = ({
    dispatch,
    module,
    options,
    moduleKey,
    nestedModules,
    generalSetting = false,
}) => {
    const { control } = useFormContext() || false // retrieve all hook methods
    const [text, setText] = useState('')
    const [interimText, setInterimText] = useState('')
    const [extraInfo, setExtraInfo] = useState(false)

    useEffect(() => {
        if (options && module) {
            setText(module[options.name])
        }
    }, [options, module])

    useEffect(() => {
        const handler = setTimeout(() => {
            if (interimText !== text) {
                setText(interimText)
            }
        }, 300)
        return () => {
            clearTimeout(handler)
        }
    }, [interimText])

    useEffect(() => {
        if (text && text !== module[options.name]) {
            const update = { ...nestedModules[moduleKey] }
            update[options.name] = text
            onModuleChange(dispatch, update, moduleKey)
        }
    }, [text]) // do not add anything other than "text" here - causes infinite loop issues when adding new modules (see 21200362) - PageBuilder needs a rewrite!

    return (
        <Box>
            <Flex>
                <Heading variant='h5' as='h5' mb='xs'>
                    {options.label}
                </Heading>
                <Box ml='xxs' onClick={() => setExtraInfo(!extraInfo)}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                </Box>
            </Flex>
            {extraInfo ? (
                <Text
                    as='p'
                    mb='xs'
                    dangerouslySetInnerHTML={{ __html: options.description }}
                />
            ) : null}
            {generalSetting ? (
                <Controller
                    control={control}
                    name={options.name}
                    render={({ field: { onChange } }) => (
                        <Editor
                            content={text}
                            onEditorChange={(newContent) => {
                                setInterimText(newContent)
                                onChange(newContent)
                            }}
                        />
                    )}
                />
            ) : (
                <Editor
                    content={interimText.length ? interimText : text}
                    onEditorChange={setInterimText}
                />
            )}
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        nestedModules: state.Pagebuilder.pagebuilder.nestedModules,
    }
}

export default connect(mapStateToProps)(Wysiwyg)
