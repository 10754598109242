import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, Heading, Text, Flex } from 'rebass/styled-components'
import { useFormContext, Controller } from 'react-hook-form'
import { Input } from '@rebass/forms/styled-components'
import { onModuleChange } from '../../helpers/Pagebuilder'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const TextField = ({ dispatch, module, options, moduleKey, nestedModules, generalSetting=false }) => {
    const { control } = useFormContext() || false // retrieve all hook methods
    const [content, updateContent] = useState('')
    const [extraInfo, setExtraInfo] = useState(false)
    const [data, setData] = useState(options)

    const handleChange = (event) => {
        const update = { ...nestedModules[moduleKey] }
        update[options.name] = event.target.value
        onModuleChange(dispatch, update, moduleKey)
        updateContent(event.target.value)
    }

    const handleHoverOff = (e) => {
        setExtraInfo(false)
    }

    useEffect(() => {
        if (options && module) {
            setData(options)
            updateContent(module[options.name])
        }
    }, [options, module])

    return (
        <Box onMouseLeave={handleHoverOff}>
            <Flex>
                <Heading variant="h5" as="h5" mb='xs'>{data.label}*</Heading>
                <Box ml='xxs' onClick={() => setExtraInfo(!extraInfo)}><FontAwesomeIcon icon={faInfoCircle} /></Box>
            </Flex>
            {extraInfo ? <Text as="p" mb='xs'>{data.description}{data.required}</Text> : null}
            {
                generalSetting ?
                    <Controller
                        control={control}
                        name={options.name}
                        render={({ field }) => {
                            return <Input
                                {...field}
                                type='text'
                                placeholder='Please enter some text...'
                                value={content}
                                onChange={(e) => {
                                    field.onChange(e.target.value)
                                    updateContent(e.target.value)
                                }} 
                            />
                        }}
                    />:
                    <Input
                        type='text'
                        placeholder='Please enter some text...'
                        value={content}
                        onChange={e => handleChange(e)} 
                    />
            }
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        nestedModules: state.Pagebuilder.pagebuilder.nestedModules,
    }
}

export default connect(mapStateToProps)(TextField)
