import React, { useState, useEffect, forwardRef } from 'react'
import { connect } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { Box, Button, Flex, Heading, Text } from 'rebass/styled-components'
import { Label, Input } from '@rebass/forms/styled-components'
import { generateSignature, showCloudinaryWidget } from '../../helpers/Cloudinary'
import { onModuleChange } from '../../helpers/Pagebuilder'
import styled from 'styled-components'
import MediaRender from '../general/MediaRender'
import DragHandle from '../general/DragHandle'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { ReactSortable } from 'react-sortablejs'
import MediaModal from '../general/MediaModal'

const sortableOptions = {
    animation: 150,
    group: 'shared',
    handle: '.draggable-handle',
    ghostClass: 'sortable-ghost',
    fallbackClass: 'sortable-fallback',
    chosenClass: 'sortable-chosen',
}

const Sortable = styled(Box)`
   width: 100%;
   .sortable-chosen:not(.sortable-ghost) { 
       background-color: transparent;
    }
`

const ImageBox = styled(Box)`
    opacity: ${(props) => props.faded ? '0.2' : '1'};
    transform-origin: 0 0;
    position: relative;
    ${(props) => props.style}

    > input {
        margin-top: calc(-1rem - 30px);
        margin-bottom: 50px;
    }
`

const Trash = styled(Box)`
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    font-size: ${(props) => props.theme.fontSizes.sm};
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 99;
    &:hover {
        color: ${(props) => props.theme.colors.red};
    }
`

const SortableWrapper = forwardRef((props, ref) => {
    return <Box ref={ref} sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridGap: 2,
    }}>{props.children}</Box>
})

SortableWrapper.displayName = 'SortableWrapper'

const Media = ({ dispatch, pageMedia, signature, module, options, moduleKey, nestedModules, generalSetting=false }) => {
    const { setValue } = useFormContext() || false // retrieve all hook methods
    const [items, setItems] = useState([])
    const [extraInfo, setExtraInfo] = useState(false)
    const [showExternalVideo, setExternalVideo] = useState(false)
    const [videoUrl, updateVideoUrl] = useState({ url: '' })
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        if (!signature) {
            generateSignature(dispatch)
        }
    }, [signature])

    useEffect(() => {
        if (module && module[options.name] && module[options.name].set) {
            setItems(module[options.name].set)
        }
    }, [module])

    const onGalleryChange = (updated) => {
        if(!generalSetting){
            const update = {
                ...nestedModules[moduleKey],
                [options.name]: {
                    ...nestedModules[moduleKey][options.name],
                    set: updated,
                },
            }
            onModuleChange(dispatch, update, moduleKey)
        } else {
            setItems(updated)
            setValue(options.name, {
                set: updated,
            })
        }
    }

    const addMediaFromGallery = (file) => {
        onGalleryChange([...items, { ...file, caption: '' }])
        setModalIsOpen(false)
    }

    const showWidget = () => {
        showCloudinaryWidget(signature, (files) => {
            onGalleryChange([...items, ...files])
        })
    }

    const externalVideo = () => {
        setExternalVideo(!showExternalVideo)
    }

    const handleSubmit = (event) => {
        const { url } = videoUrl
        event.preventDefault()
        if (url) {
            const regexYoutube = /((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)/g;
            const regexVimeo = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/g;
            
            let type = false
            if (regexYoutube.test(url)) {
                type = 'youtube'
            } else if (regexVimeo.test(url)) {
                type = 'vimeo'
            }

            if (type) {
                const updated = [...items]

                let name = url.split('\\').pop().split('/').pop()

                if (type == 'youtube') {
                    if (name.includes('watch?v=')) {
                        name = name.replace('watch?v=', '')
                    }

                    // remove possible ?si=xxx, ?t=xxx, &si=xxx, and/or &t=xxx
                    if (name.includes('?si=')) {
                        name = name.split('?si=')[0]
                    }

                    if (name.includes('?t=')) {
                        name = name.split('?t=')[0]
                    }

                    if (name.includes('&si=')) {
                        name = name.split('&si=')[0]
                    }
    
                    if (name.includes('&t=')) {
                        name = name.split('&t=')[0]
                    }
                }

                const nameSansExt = name.split('.')[0]
                const file = {
                    filename: name,
                    filepath: url,
                    media_type: 'video/' + type,
                    public_id: nameSansExt,
                }
                updated.push(file)

                // Save.
                onGalleryChange(updated)

                // Reset the URL input field.
                const change = { ...videoUrl }
                change.url = ''
                updateVideoUrl(change)
            }
        }
    }

    const onInputChange = (name, event) => {
        const change = { ...videoUrl }
        change[name]= event.target.value
        updateVideoUrl(change)
    }

    const onCaptionChange = (itemIndex, event) => {
        const updatedItems = [...items]
        updatedItems[itemIndex] = { ...items[itemIndex], caption: event.target.value }
        // setItems(updatedItems)
        onGalleryChange(updatedItems)
    }

    const remove = (index) => {
        const filtered = [...items]
        const filteredItems = filtered.slice(0, index).concat(items.slice(index + 1, items.length))
        onGalleryChange(filteredItems)
    }
    
    return (
        <Box sx={{ position: 'relative' }}>
            <Flex mb='xs'>
                <Heading variant="h5" as="h5">{options.label}</Heading>
                <Box ml='xxs' onClick={() => setExtraInfo(!extraInfo)}><FontAwesomeIcon icon={faInfoCircle} /></Box>
            </Flex>
            {
                extraInfo?
                    <Text as="p" mb='xs'>{options.description}{ options.required && ' *'}</Text>
                    :null
            }
            {
                items && items.length ?
                    <Sortable>
                        <ReactSortable
                            tag={SortableWrapper}
                            list={items.map(x => ({ ...x, chosen: true }))} // solution
                            setList={(sortedList, element) => {
                                if (element) {
                                    const updatedList = []
                                    sortedList.map((item, index) => {
                                        updatedList.push({
                                            filename: item.filename,
                                            filepath: item.filepath,
                                            media_type: item.media_type,
                                            public_id: item.public_id,
                                            caption: item.caption ?? '',
                                        })
                                    })
                                    onGalleryChange(updatedList)
                                }
                            }}
                            {...sortableOptions}
                        >
                            {items.map((item, index) => (
                                <ImageBox key={index}>
                                    <DragHandle type='media' />
                                    <Trash onClick={() => remove(index)}><FontAwesomeIcon icon={faTrashAlt} /></Trash>
                                    <MediaRender file={item} />
                                    <Input name='caption' value={item.caption} onChange={(e) => onCaptionChange(index, e)} />
                                </ImageBox>
                            ))}
                        </ReactSortable>
                    </Sortable> :
                    null
            }

            {
                (showExternalVideo) ?
                    <Flex flexDirection='column' mt='sm' mb='xxs'>
                        <Text>Enter the full URL (including https://) to the external video. (Vimeo or Youtube only)</Text>
                        <Flex alignItems="center" mt='xxs'>
                            <Label htmlFor='url' maxWidth='100px'>URL:</Label>
                            <Input
                                variant='inputSecondary'
                                id='url'
                                name='url'
                                placeholder='https://www.youtube.com/watch?v=ABC123'
                                value={videoUrl.url}
                                onChange={(e) => onInputChange( 'url', e )}
                            />
                        </Flex>
                        <Box mr='xxs'>
                            {/* {
                                generalSetting ?
                                    <Controller
                                        control={control}
                                        render={({ field: { onChange }}) => (
                                            <Button type="button" variant='primarySmall' onClick={handleSubmit(onChange)} className="upload-button">
                                                Add
                                            </Button>
                                        )}
                                    />: */}
                            <Button type="button" variant='primarySmall' onClick={handleSubmit} className="upload-button">
                                Add
                            </Button>
                            {/* } */}
                        </Box>
                    </Flex> :
                    null
            }
            <Flex pt='sm'>
                {
                    pageMedia?.galleryImage?.length ?
                        <Box mr='xxs'>
                            <Button type="button" variant='primarySmall' onClick={() => setModalIsOpen(true)} className="upload-button">
                                Add Gallery Media
                            </Button>
                        </Box>
                        : null
                }
                <Box mr='xxs'>
                    <Button type="button" variant='primarySmall' onClick={showWidget} className="upload-button">
                        {pageMedia?.galleryImage?.length ? "Add New Media" : "Add Media"}
                    </Button>
                </Box>
                <Box>
                    <Button type="button" variant='primarySmall' onClick={externalVideo} className="upload-button">
                        Add External Video
                    </Button>
                </Box>
            </Flex>
            { modalIsOpen ? <MediaModal setModalIsOpen={setModalIsOpen} addMediaFromGallery={addMediaFromGallery} /> : null }
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        nestedModules: state.Pagebuilder.pagebuilder.nestedModules,
        signature: state.Cloudinary && state.Cloudinary.signature,
        pageMedia: state.Pagebuilder.media,
    }
}
export default connect(mapStateToProps)(Media)
