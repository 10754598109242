import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'
import styled from 'styled-components'
import { useFormContext, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { onModuleChange } from '../../helpers/Pagebuilder'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const CheckBox = styled(Flex)`
   border: 2px solid ${(props) => props.theme.colors.greyLight};
   border-radius: 4px;
   cursor: pointer;
   height: 18px;
   width: 18px;
`

const CheckBoxField = ({ dispatch, module, options, moduleKey, nestedModules, generalSetting=false, onChange=null }) => {
    const { control } = useFormContext() || false // retrieve all hook methods
    const [isChecked, setChecked] = useState(module[options.name])
    const [extraInfo, setExtraInfo] = useState(false)

    useEffect(() => {
        if (options && module) {
            setChecked(module[options.name])
        }
    }, [options, module])

    useEffect(() => {
        if (isChecked !== module[options.name] && !generalSetting) {
            if (onChange) {
                onChange({
                    name: options.name, 
                    isChecked
                })
            } else {
                const update = { ...nestedModules[moduleKey] }
                update[options.name] = isChecked
                onModuleChange(dispatch, update, moduleKey)
            }
        }
    }, [isChecked])

    const handleHoverOff = (e) => {
        setExtraInfo(false)
    }

    return (
        <Box onMouseLeave={handleHoverOff}>
            <Flex justifyContent='space-between'>
                <Flex>
                    <Heading variant="h5" as="h5">{options.label}</Heading>
                    { options.description && <Box ml='xxs' onClick={() => setExtraInfo(!extraInfo)}><FontAwesomeIcon icon={faInfoCircle} /></Box> }
                </Flex>
                {
                    generalSetting ?
                        <Controller
                            control={control}
                            name={options.name}
                            render={({ field: { onChange }}) => (
                                <CheckBox alignItems="center" justifyContent="center" fontSize="10px"
                                    onClick={() => {
                                        setChecked(!isChecked)
                                        onChange(!isChecked)
                                    }}>{ isChecked && <FontAwesomeIcon icon={faCheck} /> }
                                </CheckBox>
                            )}
                        />:
                        <CheckBox alignItems="center" justifyContent="center" fontSize="10px"
                            onClick={() => setChecked( !isChecked )}>{ isChecked && <FontAwesomeIcon icon={faCheck} /> }
                        </CheckBox>
                }
               
            </Flex>
            {extraInfo?  <Text as="p">{options.description}{ options.required && ' *'}</Text> :null}
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        nestedModules: state.Pagebuilder.pagebuilder.nestedModules,
    }
}

export default connect(mapStateToProps)(CheckBoxField)
