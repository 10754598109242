import React, { useEffect, useState } from 'react'
import { updateGlobals } from '../models/Globals'
import { Box, Button } from 'rebass/styled-components'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '../system/firebase/index'
import Select from '../components/pagebuilder/Select'
import Text from '../components/pagebuilder/Text'
import { FormProvider, useForm } from 'react-hook-form'
import CheckBox from '../components/pagebuilder/Checkbox'
import ColorSelect from '../components/pagebuilder/ColorSelect'
import Media from '../components/pagebuilder/Media'
import Wysiwyg from '../components/pagebuilder/Wysiwyg'
import { updateGlobalData } from '../helpers/Functions'

const GlobalSettings = () => {
    const methods = useForm()
    const [saving, setSaving] = useState(false)
    const [saved, setSaved] = useState({ color: 'red', pointerEvents: 'auto', text: 'Save', init: false })
    const [settingContent, updateSetting] = useState({ fields: [], order: [], updated: false })
    const [formData, setData] = useState(false)
    const [globalSettings, setGlobalSettings] = useState(false)

    const onSubmit = (data) => {
        // console.log(data)
        Object.keys(data).forEach(
            key => data[key] === undefined ? delete data[key] : {}
        ) // remove undefined keys
        setData(data)
        setSaving(true)
        setTimeout(() => {
            setSaving(false)
        }, 1500)
    }

    // collapsing obejct to be one layer deep
    const combinedObj = Object.assign(
        {},
        ...function _flatten(o) {
            return [].concat(...Object.keys(o)
                .map(k => {
                    return(
                        typeof o[k] === 'object' && (!('set' in o[k])) ?
                            _flatten(o[k]) : 
                            ({ [k]: o[k] })
                    )}
                )
            );
        }(globalSettings)
    )

    useEffect(() => {
        if (saving) {
            setSaved({ color: 'green', pointerEvents: 'none', text: 'Saved', init: false })
        } else {
            setSaved({ color: 'red', pointerEvents: 'auto', text: 'Save', init: false })
        }
    }, [saving])

    useEffect(() => {
        let isCancelled = false
        onSnapshot(doc(db, 'settings', 'general'), (doc) => {
            const result = doc.data()
            if (!isCancelled && result) {
                const update = { ...settingContent }
                update.fields = result.settings
                update.order = result.order.split(',')
                updateSetting(update)
            }
        })

        return () => {
            isCancelled = true;
        }
    }, [])

    useEffect(() => {
        let isCancelled = false
        if (formData) { // there is an update
            updateGlobalData(globalSettings, formData)
            updateGlobals('globals', globalSettings)
        } else { // init
            onSnapshot (doc(db, 'globals', 'globals'), (doc) => {
                const result = doc.data()
                if (!isCancelled && result) {
                    setGlobalSettings(result)
                }
            })
        }
        return () => {
            isCancelled = true;
        }
    }, [formData])

    return (
        <FormProvider {...methods}>
            <Box as='form' onSubmit={methods.handleSubmit(onSubmit)}>
                {
                    (settingContent.order)?
                        Object.entries(settingContent.order).map(([key, orderVal]) => {
                            const val = Object.values(settingContent.fields).filter((item) => item.name === orderVal)[0]
                            if (val) {
                                let RenderedSetting = ''
                                switch (val.type) {
                                case 'Media':
                                    RenderedSetting = <Media options={val} module={combinedObj} generalSetting/>
                                    break
                                case 'Wysiwyg':
                                    RenderedSetting = <Wysiwyg options={val} module={combinedObj} generalSetting/>
                                    break
                                case 'Text':
                                    RenderedSetting = <Text options={val} module={combinedObj} generalSetting/>
                                    break
                                case 'Checkbox':
                                    RenderedSetting = <CheckBox options={val} module={combinedObj} generalSetting/>
                                    break
                                case 'Select':
                                    RenderedSetting = <Select options={val} module={combinedObj} generalSetting/>
                                    break
                                case 'ColorSelect':
                                    RenderedSetting = <ColorSelect options={val} module={combinedObj} generalSetting />
                                    break
                                default:
                                }
                                const output = <Box key={key} p='sm'>{RenderedSetting}</Box>
                                return output
                            }
                            return false
                        }) : null
                }
                <Button mt='md' bg={saved.color} sx={{
                    borderColor: saved.color,
                    pointerEvents: saved.pointerEvents }}>{saved.text}</Button>
            </Box>
        </FormProvider>
    )
}

export default GlobalSettings
