import React from 'react'
import { Editor as WYSIWYG } from '@tinymce/tinymce-react'

const Editor = ({ height = 250, onEditorChange, content }) => {
    return (
        <WYSIWYG
            // TODO: replace TinyMCE
            apiKey='gpl' // {process.env.GATSBY_TINYMCE_API_KEY ?? ""}
            tinymceScriptSrc='https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.1/tinymce.min.js'
            init={{
                height,
                menubar: 'file edit view insert format tools help table',
                plugins: 'preview paste searchreplace autolink code visualblocks visualchars fullscreen link codesample charmap hr pagebreak anchor insertdatetime advlist lists wordcount textpattern noneditable charmap quickbars table',
                toolbar:
                    'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                toolbar_mode: 'sliding',
                contextmenu: 'link',
            }}
            value={content}
            onEditorChange={onEditorChange}
        />
    )
}

export default Editor
