
import React from 'react'
import { Box } from 'rebass/styled-components'
import { SettingsSection } from '../../system/settings/pageData'
import Layout from '../../views/templates/adminLayout'
import SettingsView from '../../views/settings/index'
import GlobalSettings from '../../modules/GlobalSettings'

const PageInfo = {
    parent: SettingsSection.section,
    page: 'General',
    icon: SettingsSection.icon,
}

const Settings = () => {
    return (
        <Layout PageInfo={PageInfo} minimumRole={SettingsSection.minimumRole}>
            <SettingsView heading='General Settings'/>
            <Box variant='card' p='lg'> 
                <GlobalSettings />
            </Box>
        </Layout>
    )
}

export default Settings
