import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { useFormContext, Controller } from 'react-hook-form'
import { Box, Heading, Text, Flex } from 'rebass/styled-components'
import { onModuleChange } from '../../helpers/Pagebuilder'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const SelectField = ({ dispatch, module, options, moduleKey, nestedModules, generalSetting=false }) => {
    const { control } = useFormContext() || false // retrieve all hook methods
    const [selectedValue, setSelectedValue] = useState(module[options.name])
    const [data, setData] = useState(false)
    const [extraInfo, setExtraInfo] = useState(false)

    useEffect(() => {
        if (options && module) {
            setData(options.options)
            setSelectedValue(module[options.name])
        }
    }, [options, module])

    // handle onChange event of the dropdown
    const handleChange = (e) => {
        const update = { ...nestedModules[moduleKey] }
        update[options.name] = e.value
        onModuleChange(dispatch, update, moduleKey)
        setSelectedValue(e.value)
    }

    const handleHoverOff = (e) => {
        setExtraInfo(false)
    }

    return (
        <Box onMouseLeave={handleHoverOff} sx={{
            position: 'relative',
        }}>
            <Flex>
                <Heading variant="h5" as="h5" mb='xs'>{options.label}*</Heading>
                <Box ml='xxs' onClick={() => setExtraInfo(!extraInfo)}><FontAwesomeIcon icon={faInfoCircle} /></Box>
            </Flex>
            {extraInfo? <Text as="p" mb='xxs'>{options.description? options.description: null}{ options.required}</Text> :null}
            {
                generalSetting ?
                    <Controller
                        control={control}
                        name={options.name}
                        render={({ field: { onChange }}) => (
                            <Select
                                value={(data && selectedValue) && data.find((obj) => obj.value === selectedValue)}
                                options={data || []}
                                onChange={(e) => {
                                    setSelectedValue(e.value)
                                    onChange(e.value)
                                }}
                                styles={{ menu: provided => ({ ...provided, zIndex: 1 }) }}
                            />
                        )}
                    />:
                    <Select
                        value={(data && selectedValue) && data.find((obj) => obj.value === selectedValue)}
                        options={data || []}
                        onChange={e => handleChange(e)}
                        styles={{ menu: provided => ({ ...provided, zIndex: 1 }) }}
                    />
            }
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        nestedModules: state.Pagebuilder.pagebuilder.nestedModules,
    }
}

export default connect(mapStateToProps)(SelectField)
