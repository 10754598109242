import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormContext, Controller } from 'react-hook-form'
import { doc, onSnapshot } from 'firebase/firestore'
import { Box, Heading, Text, Flex } from 'rebass/styled-components'
import { BlockPicker } from 'react-color'
import { onModuleChange } from '../../helpers/Pagebuilder'
import { db } from '../../system/firebase/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const ColorSelect = ({ dispatch, module, options, moduleKey, nestedModules, generalSetting=false }) => {
    const { control } = useFormContext()  || false // retrieve all hook methods
    const [data, updateColors] = useState({ array: [], selected: 'transparent' })
    const [extraInfo, setExtraInfo] = useState(false)

    useEffect(() => {
        let isCancelled = false
        onSnapshot(doc(db, 'theme', 'colors'), (doc) => {
            if (!isCancelled) {
                const colorsHash = []
                Object.entries(doc.data()).map(([key, color]) => {
                    colorsHash.push(color)
                    return true
                })
                updateColors({ array: colorsHash, selected: module[options.name] || data.selected })
            }
        })

        return () => {
            isCancelled = true;
        }
    }, [module])

    const handleChange = (color, event) => {
        const filtered = Object.keys(data.array).find((key) => data.array[key] === color.hex);
        const update = { ...nestedModules[moduleKey] }
        update[options.name] = data.array[filtered] || color.hex
        updateColors({ ...data, selected: color.hex })
        onModuleChange(dispatch, update, moduleKey)
    }
    const handleHoverOff = (e) => {
        setExtraInfo(false)
    }

    return (
        <Flex alignItems={generalSetting? null :'center'} flexDirection='column' onMouseLeave={handleHoverOff}>
            <Flex>
                <Heading variant="h5" as="h5">{options.label} Colour</Heading>
                <Box ml='xxs' onClick={() => setExtraInfo(!extraInfo)}><FontAwesomeIcon icon={faInfoCircle} /></Box>
            </Flex>
            {extraInfo? <Text as="p">Please select a color:</Text> :null}
            <Box pt='sm'>
                {
                    generalSetting ?
                        <Controller
                            control={control}
                            name={options.name}
                            render={({ field: { onChange }}) => (
                                <BlockPicker color={data.selected} colors={data.array} onChangeComplete={(e) => {
                                    onChange(e.hex)
                                    updateColors({ ...data, selected: e.hex })
                                }} />
                            )}
                        />:
                        <BlockPicker color={data.selected} colors={data.array} onChangeComplete={e => handleChange(e)} />
                }
            </Box>
        </Flex>
    )
}

function mapStateToProps(state) {
    return {
        nestedModules: state.Pagebuilder.pagebuilder.nestedModules,
    }
}

export default connect(mapStateToProps)(ColorSelect)
